
import { computed, defineComponent, ref, watch } from "vue"
import { useField } from "vee-validate";

export default defineComponent({
    props:{
        nameDate: {
            type: String
            , default: "date"
        } 
        , placehoderDate: {
            type: String
            , default: ""
        }, 
        disabled: {
            type: Boolean
            , default: false
        }
    },
    setup(props){
        const fieldStartDate = useField<Date>(props.nameDate);
        const datesData = ref();
        

        const errorMessage = computed(() => {
            let error = (fieldStartDate.errorMessage.value ? (fieldStartDate.errorMessage.value ): '');
            return error;
        });

        watch(datesData, (currentValue) => {
             if(currentValue){
                fieldStartDate.setValue(currentValue.toISOString());
            }
        });
        watch(fieldStartDate.value, (currentValue) => {
            if(!currentValue || currentValue === undefined){
                datesData.value = '';
            } else {
                datesData.value = new Date(currentValue);
            }
        });
        return {
            datesData
            , fieldStartDate
            , errorMessage
            , error: fieldStartDate.errorMessage//por mientras solo este error
        }
    }
})
